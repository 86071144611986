import Repository from "./repository"

const resource = "identity"

export default {
    async add(payload) {
        Repository.headers = {
            ...Repository.headers,
            'Content-Type': 'multipart/form-data'
        };
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async update({ id, payload }) {
        Repository.headers = {
            ...Repository.headers,
            'Content-Type': 'multipart/form-data'
        };
        let endpoint = `${resource}/${id}`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async get({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async delete(id) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    },
    async enable({ id }) {
        let endpoint = `${resource}/enable/${id}`;
        let response = await Repository.put(endpoint);
        return response;
    },
    async disable({ id }) {
        let endpoint = `${resource}/disable/${id}`;
        let response = await Repository.put(endpoint);
        return response;
    },
    async getUser() {
        let endpoint = `${resource}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getUserSummary(id) {
        let endpoint = `${resource}/summary`;
        if (id !== null && id !== undefined) {
            endpoint += `/${id}`
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getUsers({ search }) {
        let endpoint = `${resource}/users`;
        if (search !== undefined && search !== "" && search !== null) {
            endpoint += `&search=${search}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async login(payload) {
        let endpoint = `${resource}/login`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async login2Fa(payload) {
        let endpoint = `${resource}/login2FA`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async setUser2Fa(payload) {
        let endpoint = `${resource}/${payload.id}/2fa`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async reset(payload) {
        let endpoint = `${resource}/reset-password`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async restore(payload) {
        let endpoint = `${resource}/restore-password`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async refresh() {
        let endpoint = `${resource}/refresh`;
        let response = await Repository.post(endpoint);
        return response;
    },
    async getLicense() {
        let endpoint = `${resource}/license`;
        let response = await Repository.get(endpoint);
        return response;
    }
}