import Repository from "./repository";

const resource = "matchReport";

export default {
    async add(payload) {
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async get({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async update(payload) {
        let endpoint = `${resource}/${payload.id}`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async finish({ id }) {
        let endpoint = `${resource}/${id}/finish`;
        let response = await Repository.post(endpoint);
        return response;
    },
    async delete({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    },
    async getAdvancedByPlayerPaged({ playerId, filters, page, take, sortBy, sortOrder }) {
        let endpoint = `${resource}/paged/advanced`;
        filters.playerId = playerId;
        filters.page = page;
        filters.take = take;
        filters.sort = {
            by: sortBy,
            order: sortOrder
        };
        let response = await Repository.post(endpoint, filters);
        return response;
    },
    async getAdvancedPaged({ filters, page, take, sortBy, sortOrder }) {
        let endpoint = `${resource}/paged/advanced/admin`;
        filters.page = page;
        filters.take = take;
        filters.sort = {
            by: sortBy,
            order: sortOrder
        };
        let response = await Repository.post(endpoint, filters);
        return response;
    },
    async getSharedMatchReport({ id }) {
        let endpoint = `${resource}/${id}/share/complete`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getMatchReportShare({ id }) {
        let endpoint = `${resource}/${id}/share`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async share({ id }) {
        let endpoint = `${resource}/${id}/share`;
        let response = await Repository.put(endpoint);
        return response;
    },
    async unshare({ id }) {
        let endpoint = `${resource}/${id}/share`;
        let response = await Repository.delete(endpoint);
        return response;
    }
}