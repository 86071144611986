import Repository from "./repository";
import store from '@/store';

const resource = "snapshot";

function getScoutFromCache(id, scoutType) {
    let snapshot = store.getters["snapshot/getSnapshot"](id, scoutType);
    return snapshot ?? null;
}

function addScoutToCache(id, scoutType, snapshot) {
    store.dispatch('snapshot/addSnapshot', {
        id: id,
        scoutType: scoutType,
        snapshot: snapshot
    });
}

export default {
    async add(payload) {
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async get({ id, scoutType }) {
        let snapshot = getScoutFromCache(id, scoutType);
        if (snapshot !== null) return { data: snapshot };

        let endpoint = `${resource}/${id}/${scoutType}`;
        let response = await Repository.get(endpoint);

        addScoutToCache(id, scoutType, response.data);

        return response;
    },
    async getSnapshots({ id, position }) {
        let endpoint = `${resource}/${id}/${position}/list`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async delete({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    }
}