import Vue from "vue";

export const club = {
    namespaced: true,
    state: {
        isLoading: true,
        club: null
    },
    actions: {
        setLoading({ commit }, loading) {
            commit('setLoading', loading);
        },
        setClub({ commit }, club) {
            commit('setClub', club);
        },
    },
    mutations: {
        setLoading(state, loading) {
            Vue.set(state, 'isLoading', loading);
        },
        setClub(state, club) {
            Vue.set(state, 'club', club);
        }
    },
    getters: {
        isLoading: state => state.isLoading,
        getClub: state => state.club
    }
}
