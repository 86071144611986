import Vue from "vue"

export const teams = {
    namespaced: true,
    state: {
        position: null
    },
    actions: {
        setPosition({ commit }, position) {
            commit('setPosition', position);
        }
    },
    mutations: {
        setPosition(state, position) {
            Vue.set(state, 'position', position);
        },

    },
    getters: {
        getPosition: state => state.position
    }
}