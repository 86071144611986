<template>
    <div>
        <component :is="layout">
            <router-view />
        </component>
        <notifications :duration="7000" />
    </div>
</template>

<script>
const defaultLayout = "default";

export default {
    name: "App",
    computed: {
        layout() {
            let layout = this.$route.meta.layout || defaultLayout;
            return layout + "-layout";
        },
    },
};
</script>
