import Vue from "vue";

export const comparer = {
    namespaced: true,
    state: {
        showDifference: false,
        showSelectScout: false,
    },
    actions: {
        setShowDifference({ commit }, show) {
            commit('setShowDifference', show);
        },
        setShowSelectScout({ commit }, show) {
            commit('setShowSelectScout', show);
        }
    },
    mutations: {
        setShowDifference(state, show) {
            Vue.set(state, 'showDifference', show);
        },
        setShowSelectScout(state, show) {
            Vue.set(state, 'showSelectScout', show);
        }
    },
    getters: {
        showDifference: state => state.showDifference,
        showSelectScout: state => state.showSelectScout
    }
};