import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { RepositoryFactory } from "@/services/api"

Vue.use(VueI18n)

// TODO: https://codesandbox.io/s/o7n6pkpwoy SELECT LANGUAGE

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = Object.assign({}, messages[locale], locales(key))
        }
    })
    return messages;
}

export const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'es',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
    messages: loadLocaleMessages()
});

// Lazy languages loading
const loadedLanguages = [];

export async function loadLanguageAsync(lang) {
    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return;
    }

    let response = await RepositoryFactory.language.get(lang);

    let messages = Object.assign(i18n.messages[lang], response.data);

    i18n.setLocaleMessage(lang, messages);
    loadedLanguages.push(lang);
}
