import IdentityRepository from "./identity.repository";
import LeagueRepository from "./league.repository";
import TeamRepository from "./team.repository";
import PlayerRepository from "./player.repository";
import ScoutRepository from "./scout.repository";
import AdminRepository from "./admin.repository";
import MetricRepository from "./metric.repository";
import LanguageRepository from "./language.repository";
import RecordRepository from "./record.repository";
import MatchRepository from "./match.repository";
import SnapshotRepository from "./snapshot.repository";
import PeriodicClosureRepository from "./periodic-closure.repository";

export const RepositoryFactory = {
    identity: IdentityRepository,
    league: LeagueRepository,
    team: TeamRepository,
    player: PlayerRepository,
    scout: ScoutRepository,
    admin: AdminRepository,
    metric: MetricRepository,
    language: LanguageRepository,
    record: RecordRepository,
    match: MatchRepository,
    snapshot: SnapshotRepository,
    periodicClosure: PeriodicClosureRepository
}