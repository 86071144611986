import Repository from "./repository"

import { buildPaginationQuery } from "../pagination/index";

const resource = "scout"

export default {
    async get({ id, scoutType }) {
        let endpoint = `${resource}/${id}/${scoutType}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPaged(params) {
        let endpoint = `${resource}/paged`;
        endpoint = buildPaginationQuery(endpoint, params);
        let response = await Repository.get(endpoint);
        return response;
    },
    async getFavoritesPaged(params) {
        let endpoint = `${resource}/favorites/paged`;
        endpoint = buildPaginationQuery(endpoint, params);
        let response = await Repository.get(endpoint);
        return response;
    },
    async getUserScoutsPaged(params) {
        let endpoint = `${resource}/byUser/paged`;
        endpoint = buildPaginationQuery(endpoint, params);
        let response = await Repository.get(endpoint);
        return response;
    },
    async getUserFavoriteScoutsPaged(params) {
        let endpoint = `${resource}/favorites/byUser/paged`;
        endpoint = buildPaginationQuery(endpoint, params);
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPlayerScouts({ id }) {
        let endpoint = `${resource}/player/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getAllPlayerScouts({ id }) {
        let endpoint = `${resource}/player/${id}/all`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPlayerAverageScout({ id, position, scoutType }) {
        let endpoint = `${resource}/${id}/${position}/summary/${scoutType}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async initialize({ playerId, position, scoutType }) {
        let endpoint = `${resource}`;
        let payload = {
            playerId,
            position,
            scoutType
        };
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async update({ id, changes, scoutType, preferencialTendencies, descriptorChanges, observations, playerKnowledge }) {
        let endpoint = `${resource}/update/${id}`;
        let payload = {
            scoutType: scoutType,
            ratingChanges: changes,
            preferencialTendencies: preferencialTendencies,
            descriptorChanges: descriptorChanges,
            observations: observations,
            playerKnowledge: playerKnowledge
        };
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async delete({ id }) {
        let endpoint = `${resource}/${id}`
        let response = await Repository.delete(endpoint);
        return response;
    },
    async getSharedScout({ id }) {
        let endpoint = `${resource}/${id}/share/complete`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getScoutShare({ id }) {
        let endpoint = `${resource}/${id}/share`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async share({ id }) {
        let endpoint = `${resource}/${id}/share`;
        let response = await Repository.put(endpoint);
        return response;
    },
    async unshare({ id }) {
        let endpoint = `${resource}/${id}/share`;
        let response = await Repository.delete(endpoint);
        return response;
    }
}