import { identity } from "./identity";
import { leagues } from "./league";
import { teams } from "./team";
import { scout } from "./scout";
import { users } from "./users";
import { record } from "./record";
import { club } from "./club";
import { snapshot } from "./snapshot";
import { comparer } from "./comparer";

export default {
    identity,
    leagues,
    teams,
    scout,
    users,
    record,
    club,
    snapshot,
    comparer
}