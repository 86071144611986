import store from '@/store'
import tokenService from "@/services/identity/tokens.js"

export function vuexIdentityCreateRouterMiddleware(to, from, next) {
    let meta = to.meta;
    if (!meta.isPublic) {
        if (from.name === null) { // First page access            
            let token = tokenService.readTokenFromStorage();
            if (token !== undefined) {
                store.dispatch('identity/addToken', { token });
            }
        }

        let isAuthenticated = store.getters["identity/isAuthenticated"]
        if (!isAuthenticated) {
            next({
                name: 'login'
            });
        } else {
            next();
        }
    } else {
        next();
    }
}