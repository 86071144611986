<template>
    <b-navbar-nav class="fw-light ms-2 main-bar">
        <template v-if="isLogged">
            <b-nav-text class="me-2" right @click="goProfile">
                <Icon name="user" helperClass="is-pointer" />
            </b-nav-text>
            <b-nav-text right>
                <span class="d-only-xl"> {{ name }} </span>
            </b-nav-text>
            <b-nav-text class="ms-2" right @click="signOut">
                <Icon name="door-open" />
            </b-nav-text>
        </template>
    </b-navbar-nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { RepositoryFactory } from "@/services/api";

import tokenService from "@/services/identity/tokens.js";

export default {
    name: "sso",
    data() {
        return {
            timer: null,
        };
    },
    mounted() {
        this.checkSesionExpiration();
        this.startTimer();
    },
    beforeDestroy() {
        this.stopTimer();
    },
    computed: {
        ...mapGetters("identity", [
            "isAuthenticated",
            "getExpiration",
            "getUser",
        ]),
        isLogged() {
            return this.isAuthenticated;
        },
        name() {
            return this.getUser.name;
        },
    },
    methods: {
        ...mapActions("identity", ["logout", "addToken"]),
        signOut() {
            this.logout();
            this.$router.push({
                name: "login",
            });
        },
        checkSesionExpiration() {
            if (!this.isLogged) {
                return;
            }

            let expiration = this.getExpiration;
            let expired = tokenService.isExpired(expiration);
            if (expired) {
                this.signOut();
            } else {
                let remaining = tokenService.getRemaining(expiration);
                if (remaining < 10 * 60) {
                    this.refreshToken();
                }
            }
        },
        async refreshToken() {
            try {
                let response = await RepositoryFactory.identity.refresh();
                let token = response.data;
                this.addToken({ token });
                window.console.log("Refreshed token!");
            } catch {
                return;
            }
        },
        startTimer() {
            this.timer = setInterval(() => {
                this.checkSesionExpiration();
            }, 60 * 1000);
        },
        stopTimer() {
            clearInterval(this.timer);
        },
        goProfile() {
            this.$router.push({
                name: "profile",
            });
        },
    },
};
</script>
