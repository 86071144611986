import Vue from "vue";

import { RepositoryFactory } from "@/services/api"

export const users = {
    namespaced: true,
    state: {
        users: []
    },
    actions: {
        async loadUsers({ commit }) {
            if (users.length === 0) {
                return;
            }

            let response = await RepositoryFactory.identity.getUsers({});
            commit("setUsers", { users: response.data });
        }
    },
    mutations: {
        setUsers(state, { users }) {
            for (const user of users) {
                user.completeName = `${user.name.trim()} ${user.subnames.trim()}`.trim();
            }
            Vue.set(state, 'users', users);
        }
    },
    getters: {
        findUser: state => id => state.users.find(u => u.id === id),
        getUsers: state => state.users
    }
}