<template>
    <div>
        <HorizontalNavbar />
        <b-container fluid>
            <slot />
        </b-container>
        <NewVersionPopUp />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { loadLanguageAsync } from "../i18n";

import HorizontalNavbar from "./HorizontalNavbar.vue";
import NewVersionPopUp from "./NewVersionPopUp.vue";


export default {
    name: "layout-default",
    components: {
        HorizontalNavbar,
        NewVersionPopUp,
    },
    mounted() {
        if (this.isAuthenticated) {
            this.retrieveLanguages();
            this.loadUsers();
        }
    },
    computed: {
        ...mapGetters("identity", ["isAuthenticated"]),
    },
    methods: {
        ...mapActions("users", ["loadUsers"]),
        async retrieveLanguages() {
            await loadLanguageAsync("es");
            await loadLanguageAsync("en");
        },
    },
};
</script>