<template>
    <div>
        <b-navbar
            v-if="showNavbar"
            type="light"
            variant="default"
            class="shadow-sm py-0 px-3"
        >
            <b-navbar-brand to="/club" class="nav-title">
                <img
                    class="nav-icon hidden-after-md"
                    src="@/assets/images/logo2.png"
                    alt="Icon-big"
                />
                <img
                    class="nav-icon show-before-md"
                    src="@/assets/images/logo-simple.png"
                    alt="Icon-small"
                />
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse" class="mx-4">
                <template #default="{ expanded }">
                    <Icon v-if="expanded" name="chevron-up" />
                    <Icon v-else name="chevron-down" />
                </template>
            </b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="portal-nav ms-auto me-xl-5">
                    <b-nav-item
                        v-if="allowRecords"
                        class="nav-menu-text"
                        :to="{ name: 'main-record' }"
                    >
                        <Icon name="thumbtack" />
                    </b-nav-item>
                    <b-nav-item
                        v-if="allowClub"
                        class="nav-menu-text"
                        :to="{ name: 'club' }"
                    >
                        <Icon name="people-group" />
                        <span class="hidden-md hidden-sm hidden-xs">
                            {{ $t("navbar.club") }}
                        </span>
                    </b-nav-item>

                    <b-nav-item-dropdown class="nav-menu-dropdown">
                        <template #button-content>
                            <Icon name="binoculars" />
                            <span class="hidden-md hidden-sm hidden-xs">
                                {{ $t("navbar.scouts") }}
                            </span>
                        </template>

                        <b-dropdown-item
                            class="nav-menu-option"
                            :to="{ name: 'scouts' }"
                        >
                            <Icon name="binoculars" />
                            {{ $t("navbar.players") }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="isAdmin || isSuperAdmin"
                            class="nav-menu-option"
                            :to="{ name: 'matches' }"
                        >
                            <img
                                :src="require('@/assets/images/whistle.png')"
                                width="25px"
                                alt="whistle"
                            />
                            {{ $t("navbar.matches") }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="allowSearch"
                            class="nav-menu-option"
                            :to="{ name: 'players-comparer' }"
                        >
                            <Icon name="less-than-equal" />
                            {{ $t("navbar.players-comparer") }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="allowSearch"
                            class="nav-menu-option"
                            :to="{ name: 'players-search' }"
                        >
                            <Icon name="magnifying-glass" />
                            {{ $t("navbar.players-search") }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>

                    <b-nav-item-dropdown class="nav-menu-dropdown">
                        <template #button-content>
                            <Icon name="briefcase" />
                            <span class="hidden-md hidden-sm hidden-xs">
                                {{ $t("navbar.management") }}
                            </span>
                        </template>
                        <b-dropdown-item
                            class="nav-menu-option"
                            :to="{ name: 'players' }"
                        >
                            <Icon name="person-running" />
                            {{ $t("navbar.players") }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            class="nav-menu-option"
                            :to="{ name: 'teams' }"
                        >
                            <Icon name="shield-halved" />
                            {{ $t("navbar.teams") }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            class="nav-menu-option"
                            :to="{ name: 'leagues' }"
                        >
                            <Icon name="trophy" />
                            {{ $t("navbar.leagues") }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="allowRecords"
                            class="nav-menu-option"
                            :to="{ name: 'records' }"
                        >
                            <Icon name="list" />
                            {{ $t("navbar.records") }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="allowUsers"
                            class="nav-menu-option"
                            :to="{ name: 'users' }"
                        >
                            <Icon name="users" />
                            {{ $t("navbar.users") }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>

                    <b-nav-item-dropdown
                        v-if="isSuperAdmin || allowPeriodicClosure"
                        class="nav-menu-dropdown"
                    >
                        <template #button-content>
                            <Icon name="gear" />
                            <span class="hidden-md hidden-sm hidden-xs">
                                {{ $t("navbar.settings") }}
                            </span>
                        </template>
                        <b-dropdown-item
                            v-if="isSuperAdmin"
                            class="nav-menu-option"
                            :to="{ name: 'metric' }"
                        >
                            <Icon name="chart-pie" />
                            {{ $t("navbar.metric") }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="allowPeriodicClosure"
                            class="nav-menu-option"
                            :to="{ name: 'periodic-closures' }"
                        >
                            <Icon name="clock" />
                            {{ $t("navbar.periodic-closures") }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
                <Language v-if="allowMultilanguage" />
                <Sso />
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import Sso from "@/components/identity/Sso.vue";
import Language from "@/components/Language.vue";

export default {
    name: "horizontal-nav-bar",
    components: {
        Sso,
        Language,
    },
    computed: {
        ...mapGetters("identity", [
            "allowUsers",
            "allowSearch",
            "allowClub",
            "allowMultilanguage",
            "allowRecords",
            "allowHistorical",
            "isAdmin",
            "isSuperAdmin",
        ]),
        showNavbar() {
            let meta = this.$route.meta;
            if (meta.hideNavbar === true) {
                return false;
            }
            return true;
        },
        allowPeriodicClosure() {
            return this.allowHistorical && this.isSuperAdmin;
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-icon {
    width: 35x;
    height: 35px;
}

.nav-menu-text {
    font-weight: 500;
    text-align: center;
}

.nav-menu-option {
    font-weight: 500;
    text-align: start;
}

.nav-menu-dropdown {
    text-align: center;
    padding: 0.3rem 0.3rem;
}

.portal-nav .nav-link {
    padding: 0.75rem 1rem;
}

.portal-nav .router-link-exact-active {
    border-bottom: 4px solid #009877;
}

/*toggler*/
.navbar-toggler {
    padding: 0.25rem 0.5rem;
    border-radius: 0;
    font-size: unset;
}
</style>