import Vue from 'vue'
import VueRouter from 'vue-router'

import { vuexIdentityCreateRouterMiddleware } from './middlewares.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  // Players
  {
    path: '/players',
    name: 'players',
    component: () => import('../views/players/Players.vue')
  },
  {
    path: '/players/add',
    name: 'create-player',
    component: () => import('../views/players/CreatePlayer.vue')
  },
  {
    path: '/player/:id',
    name: 'player',
    component: () => import('../views/players/Player.vue'),
    props: true
  },
  {
    path: '/player/:id/edit',
    name: 'player-edit',
    component: () => import('../views/players/PlayerEdit.vue'),
    props: true
  },
  {
    path: '/shared/player/:id',
    name: 'shared-player',
    component: () => import('../views/players/SharedPlayer.vue'),
    props: true,
    meta: { layout: 'full-screen', isPublic: true }
  },
  // Teams
  {
    path: '/teams',
    name: 'teams',
    component: () => import('../views/teams/Teams.vue')
  },
  {
    path: '/teams/add',
    name: 'create-team',
    component: () => import('../views/teams/CreateTeam.vue')
  },
  {
    path: '/team/:id',
    name: 'team',
    component: () => import('../views/teams/Team.vue'),
    props: true
  },
  {
    path: '/team/:id/lineup',
    name: 'team-lineup',
    component: () => import('../views/teams/Lineup.vue'),
    props: true
  },
  {
    path: '/team/:id/edit',
    name: 'team-edit',
    component: () => import('../views/teams/TeamEdit.vue'),
    props: true
  },
  // Leagues
  {
    path: '/leagues',
    name: 'leagues',
    component: () => import('../views/leagues/Leagues.vue')
  },
  {
    path: '/leagues/add',
    name: 'create-league',
    component: () => import('../views/leagues/CreateLeague.vue')
  },
  {
    path: '/league/:id',
    name: 'league',
    component: () => import('../views/leagues/League.vue'),
    props: true
  },
  {
    path: '/league/:id/edit',
    name: 'league-edit',
    component: () => import('../views/leagues/LeagueEdit.vue'),
    props: true
  },
  // Scouts
  {
    path: '/scouts',
    name: 'scouts',
    component: () => import('../views/scouts/Scouts.vue')
  },
  {
    path: '/scout/:playerId/summary',
    name: 'scout-summary',
    component: () => import('../views/scouts/ScoutSummary.vue'),
    props: true
  },
  {
    path: '/:playerId/comparer',
    name: 'scouts-comparer',
    component: () => import('../views/scouts/ScoutsComparer.vue'),
    props: true
  },
  {
    path: '/comparer/:playerId?/:position?/:secondPlayerId?/:secondPlayerPosition?',
    name: 'players-comparer',
    component: () => import('../views/players/PlayersComparer.vue'),
    props: (route) => {
      let params = route.params;
      return {
        playerId: params.playerId,
        position: Number(params.position),
        secondPlayerId: params.secondPlayerId,
        secondPlayerPosition: Number(params.secondPlayerPosition)
      }
    }
  },
  {
    path: '/scout/:playerId/:position?/:type?',
    name: 'scout',
    component: () => import('../views/scouts/Scout.vue'),
    props: (route) => {
      let type = route.params.type;
      type = type ?? 0;
      return {
        playerId: route.params.playerId,
        position: Number(route.params.position),
        type: Number(type)
      }
    }
  },
  {
    path: '/shared/scout/:id',
    name: 'shared-scout',
    component: () => import('../views/scouts/SharedScout.vue'),
    props: true,
    meta: { layout: 'full-screen', isPublic: true }
  },
  {
    path: '/shared/match/:id',
    name: 'shared-match-report',
    component: () => import('../views/matches/SharedMatchReport.vue'),
    props: true,
    meta: { layout: 'full-screen', isPublic: true }
  },
  {
    path: '/players/search',
    name: 'players-search',
    component: () => import('../views/players/Search.vue')
  },
  {
    path: '/club',
    name: 'club',
    component: () => import('../views/Club.vue')
  },
  // Records
  {
    path: '/records',
    name: 'records',
    component: () => import('../views/records/Records.vue')
  },
  {
    path: '/record/:id',
    name: 'record',
    component: () => import('../views/records/Record.vue'),
    props: true
  },
  {
    path: '/main-record',
    name: 'main-record',
    component: () => import('../views/records/MainRecord.vue')
  },
  // Matches
  {
    path: '/player/:playerId/matches',
    name: 'player-matches',
    component: () => import('../views/matches/PlayerMatches.vue'),
    props: true
  },
  {
    path: "/match/:id",
    name: "match",
    component: () => import("../views/matches/Match.vue"),
    props: true
  },
  {
    path: "/matches",
    name: 'matches',
    component: () => import("../views/matches/Matches.vue"),
  },
  // Historical
  {
    path: "/player/:id/historical",
    name: "historical",
    component: () => import("../views/historical/PlayerHistorical.vue"),
    props: true
  },
  {
    path: "/periodic-closures",
    name: 'periodic-closures',
    component: () => import("../views/periodic-closure/PeriodicClosures.vue"),
  },
  // Identity
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('../views/users/User.vue'),
    props: true
  },
  {
    path: '/create-user',
    name: 'create-user',
    component: () => import('../views/users/CreateUser.vue')
  },
  {
    path: '/user/:id/update',
    name: 'update-user',
    component: () => import('../views/users/UpdateUser.vue'),
    props: true
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/users/Users.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: { layout: 'full-screen', isPublic: true }
  },
  {
    path: "/reset-password",
    name: 'reset-password',
    component: () => import("../views/identity/ResetPassword.vue"),
    meta: { layout: "full-screen", isPublic: true }
  },
  {
    path: "/restore-password",
    name: 'restore-password',
    component: () => import("../views/identity/RestorePassword.vue"),
    meta: { layout: "full-screen", isPublic: true }
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/identity/Profile.vue")
  },
  // Back Room
  {
    path: '/metric',
    name: 'metric',
    component: () => import('../views/Metric.vue')
  },
  {
    path: '/back-room',
    name: 'back-room',
    component: () => import('../views/admin/BackRoom.vue')
  }, {
    path: '/bulk-upload',
    name: 'bulk-upload',
    component: () => import('../views/admin/BulkUpload.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(vuexIdentityCreateRouterMiddleware);

export default router
