import Vue from "vue";

export const record = {
    namespaced: true,
    state: {
        isLoading: false,
        isLoadingPlayers: false,
        record: null,
        players: []
    },
    actions: {
        setRecord({ commit }, record) {
            commit('setRecord', record);
        },
        setPlayers({ commit }, players) {
            commit('setPlayers', players);
        },
        setLoading({ commit }, loading) {
            commit('setLoading', loading);
        },
        setLoadingPlayers({ commit }, loading) {
            commit('setLoadingPlayers', loading);
        },
    },
    mutations: {
        setRecord(state, record) {
            Vue.set(state, 'record', record);
        },
        setPlayers(state, players) {
            Vue.set(state, 'players', players);
        },
        setLoading(state, loading) {
            Vue.set(state, 'isLoading', loading);
        },
        setLoadingPlayers(state, loading) {
            Vue.set(state, 'isLoadingPlayers', loading);
        },
    },
    getters: {
        isLoading: state => state.isLoading,
        isLoadingPlayers: state => state.isLoadingPlayers,
        getRecord: state => state.record,
        getPlayers: state => state.players,
        getPlayersCount: state => state.players.length
    }
} 