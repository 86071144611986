import Repository from "./repository";

const resource = "periodicClosure";

export default {
    async getAll() {
        let endpoint = `${resource}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async add(payload) {
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async update(payload) {
        let endpoint = `${resource}/${payload.id}`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async delete(id) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    }
}