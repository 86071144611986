import Vue from "vue"

export const scout = {
    namespaced: true,
    state: {
        changes: [],
        readonly: false,
        preferencialTendencies: null,
        descriptors: [],
        observations: null,
        playerKnowledge: null
    },
    actions: {
        addChange({ commit }, change) {
            commit('addChange', change)
        },
        setReadonly({ commit }, readonly) {
            commit('setReadonly', readonly);
        },
        reset({ commit }) {
            commit('reset')
        },
        setPreferencialTendencies({ commit }, preferencialTendencies) {
            commit('setPreferencialTendencies', preferencialTendencies);
        },
        setDescriptor({ commit }, descriptor) {
            commit('setDescriptor', descriptor);
        },
        setObservations({ commit }, observations) {
            commit("setObservations", observations);
        },
        setPlayerKnowledge({ commit }, playerKnowledge) {
            commit('setPlayerKnowledge', playerKnowledge);
        }
    },
    mutations: {
        addChange(state, change) {
            state.changes.push(change);
            Vue.set(state, 'changes', state.changes);
        },
        setReadonly(state, readonly) {
            Vue.set(state, 'readonly', readonly);
        },
        reset(state) {
            Vue.set(state, 'changes', []);
            Vue.set(state, 'preferencialTendencies', null);
            Vue.set(state, 'descriptors', []);
        },
        setPreferencialTendencies(state, preferencialTendencies) {
            Vue.set(state, 'preferencialTendencies', preferencialTendencies);
        },
        setDescriptor(state, descriptor) {
            let descriptors = state.descriptors;
            let actualDescriptor = descriptors.find(d => d.key == descriptor.key);
            if (actualDescriptor === undefined) {
                descriptors.push(descriptor);
            } else {
                actualDescriptor.descriptorType = descriptor.descriptorType
            }
            Vue.set(state, 'descriptors', descriptors);
        },
        setObservations(state, observations) {
            Vue.set(state, "observations", observations);
        },
        setPlayerKnowledge(state, playerKnowledge) {
            Vue.set(state, 'playerKnowledge', playerKnowledge);
        }
    },
    getters: {
        getChanges: state => state.changes,
        getReadonly: state => state.readonly,
        getPreferencialTendencies: state => state.preferencialTendencies,
        getDescriptors: state => state.descriptors,
        getObservations: state => state.observations,
        getPlayerKnowledge: state => state.playerKnowledge
    }
}