<template>
    <div>
        <b-row class="justify-content-center">
            <b-col cols="6" align-self="center">
                <b-form-input
                    v-model="values[1]"
                    type="number"
                    size="sm"
                    :min="min"
                    :max="max"
                    :step="step"
                    @change="emitChange"
                />
            </b-col>
        </b-row>
        <b-row class="justify-content-center mt-3">
            <b-col cols="2" align-self="center">
                <VueSlider
                    v-if="loaded"
                    v-model="values"
                    direction="btt"
                    height="100px"
                    @drag-end="emitChange"
                    :min="min"
                    :max="max"
                    :interval="step"
                    :marks="marks"
                />
            </b-col>
        </b-row>
        <b-row class="justify-content-center mt-3">
            <b-col cols="6" align-self="center">
                <b-form-input
                    v-model="values[0]"
                    type="number"
                    size="sm"
                    :min="min"
                    :max="max"
                    :step="step"
                    @change="emitChange"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import VueSlider from "vue-slider-component";

export default {
    name: "vertical-range",
    components: {
        VueSlider,
    },
    props: {
        value: {
            type: Array,
            default: () => [0, 5],
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        step: {
            type: Number,
            default: 0.1,
        },
        marks: {
            type: Array,
            default: () => [0, 1, 2, 3, 4, 5],
        },
    },
    data() {
        return {
            loaded: false,
            values: [0, 5],
        };
    },
    mounted() {
        if (this.value !== null && this.value.length > 0) {
            if (this.value < this.min) {
                this.values = [this.min, this.values[1]];
            } else {
                this.values = this.value;
            }
        } else {
            this.values = [this.min, this.max];
        }
        this.loaded = true;
    },
    watch: {
        value(value) {
            this.values = value;
        },
    },
    methods: {
        emitChange() {
            this.$emit("input", this.values);
        },
    },
};
</script>