<template>
    <span :class="helperClass" @click="click">
        <font-awesome-icon :icon="name" :size="size" :spin="spin" />
    </span>
</template>

<script>
export default {
    name: "Icon",
    props: {
        name: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: null,
        },
        helperClass: {
            type: String,
        },
        spin: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        click() {
            this.$emit("click");
        },
    },
};
</script>