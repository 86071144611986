import Vue from "vue";
import tokenService from "@/services/identity/tokens.js";
import licenseService from "@/services/license/license.js"

const levels = ["N0", "N1", "N2", "N3", "N4", "N5"];

function isInRole(roles, role) {
    var isIn = roles.indexOf(role) > -1;
    return isIn;
}

function allowViewLevel(license, roles, level) {
    var allow = license[`allow${level}`];
    if (!allow) return false;

    allow = allowEditLevel(license, roles, level);
    if (allow) return true;

    //Get the high role
    let levelRoles = roles.filter(r => levels.indexOf(r) > -1)
    let maxLevel = levelRoles[levelRoles.length - 1];
    return level <= maxLevel;
}

function allowEditLevel(license, roles, level) {
    var allow = license[`allow${level}`];
    if (!allow) return false;

    allow = isSuperAdmin(roles) || isAdmin(roles);
    if (allow) return true;

    return isInRole(roles, level)
}

function isSuperAdmin(roles) {
    return isInRole(roles, "SuperAdmin")
}

function isAdmin(roles) {
    return isInRole(roles, "Admin")
}

function allow(roles, role) {
    if (isAdmin(roles)) { return true; }
    if (isSuperAdmin(roles)) { return true; }
    return isInRole(roles, role);
}

export const identity = {
    namespaced: true,
    state: {
        isAuthenticated: false,
        user: null,
        accessToken: null,
        exp: null,
        roles: [],
        levelsEdit: [],
        levelsView: [],
        license: {}
    },
    actions: {
        addToken({ commit }, { token }) {
            let user = tokenService.parseJwt(token);
            commit('storeLicense', user.license);
            commit('storeUser', { user });
            commit('storeRoles', { roles: user.role });
            commit('storeToken', { token, exp: user.exp });
            tokenService.storeTokenToStorage(token);
        },
        setLicense({ commit }, license) {
            commit('storeLicense', license);
        },
        logout({ commit }) {
            commit('logout')
        }
    },
    mutations: {
        storeToken(state, { token, exp }) {
            Vue.set(state, 'accessToken', token);
            Vue.set(state, 'isAuthenticated', true);
            Vue.set(state, 'exp', exp);
        },
        storeRoles(state, { roles }) {
            if (roles === undefined) {
                return;
            }
            if (typeof roles === "string") {
                roles = [roles];
            } else {
                roles = roles.sort();
            }
            Vue.set(state, 'roles', roles);

            let levelsEdit = [];
            let levelsView = [];
            for (const level of levels) {
                if (allowEditLevel(state.license, state.roles, level)) {
                    levelsEdit.push(level);
                    levelsView.push(level);
                } else if (allowViewLevel(state.license, state.roles, level)) {
                    levelsView.push(level);
                }
            }
            Vue.set(state, 'levelsEdit', levelsEdit);
            Vue.set(state, 'levelsView', levelsView);
        },
        storeUser(state, { user }) {
            Vue.set(state, 'user', user);
        },
        storeLicense(state, token) {
            var license = licenseService.parse(token);
            Vue.set(state, 'license', license);
        },
        logout(state) {
            Vue.set(state, 'accessToken', null);
            Vue.set(state, 'user', null);
            Vue.set(state, 'isAuthenticated', false);
            Vue.set(state, 'exp', null);
            Vue.set(state, 'roles', []);
            Vue.set(state, 'levelsEdit', []);
            Vue.set(state, 'levelsView', []);
            Vue.set(state, 'license', {});
            tokenService.removeTokenFromStorage();
        }
    },
    getters: {
        isAuthenticated: state => state.isAuthenticated,
        getUser: state => state.user,
        getUserId: state => state.user?.id,
        getAccesToken: state => state.accessToken,
        getExpiration: state => state.exp,
        getLicense: state => state.license,

        allowPremium: state => state.license.allowPremium,
        allowViewLevel: state => level => state.levelsView.indexOf(level) > -1,
        allowEditLevel: state => level => state.levelsEdit.indexOf(level) > -1,

        getViewLevels: state => state.levelsView,
        getEditLevels: state => state.levelsEdit,

        isSuperAdmin: state => isSuperAdmin(state.roles),
        isAdmin: state => isAdmin(state.roles),

        allowUsers: state => state.license.allowUsers && (isAdmin(state.roles) || isSuperAdmin(state.roles)),

        allowPlayerAverage: state => allow(state.roles, "PlayerAverage"),
        allowScoutComparer: state => state.license.allowScoutsComparer && allow(state.roles, "ScoutComparer"),
        allowPlayerComparer: state => state.license.allowPlayersComparer && allow(state.roles, "PlayerComparer"),
        allowSearch: state => allow(state.roles, "Search"),
        allowLineup: state => state.license.allowField && allow(state.roles, "Lineup"),
        allowRecords: state => state.license.allowRecords,
        allowHistorical: state => state.license.allowHistorical && allow(state.roles, "Historical"),
        allowCreate: state => state.license.allowCreate && allow(state.roles, "Create"),
        allowUpdate: state => state.license.allowUpdate && allow(state.roles, "Update"),
        allowDelete: state => state.license.allowDelete && allow(state.roles, "Delete"),
        allowShare: state => state.license.allowShare && allow(state.roles, "Share"),
        allowSecondPosition: state => state.license.allowSecondPosition,
        allowPlayerKnowledge: state => state.license.allowPlayerKnowledge,
        allowTeamPreferences: state => state.license.allowTeamPreferences,
        allowClub: state => state.license.allowClub,
        allowPlayerTransference: state => state.license.allowPlayerTransference,
        allowMultilanguage: state => state.license.allowMultilanguage
    }
}
