import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { i18n } from './i18n'

// Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFutbol, faChevronLeft, faChevronRight, faChevronDown, faChevronUp, faBriefcase,
  faPlus, faQuestion, faMagnifyingGlass, faX, faCheck, faRotate, faDoorOpen,
  faTrophy, faShieldHalved, faPersonRunning, faPencil, faTrashCan, faCrown, faEye,
  faEyeSlash, faXmark, faMinus, faUser, faUsers, faFileArrowUp, faArrowUp,
  faArrowDown, faEquals, faBinoculars, faGlobe, faEllipsis, faPeopleGroup,
  faUserTie, faStar, faPersonWalkingDashedLineArrowRight, faImage, faVideo,
  faChartPie, faEarthAmericas, faList, faShareNodes, faTable, faGrip, faLock,
  faSquareCheck, faSlash, faThumbTack, faArrowRight, faArrowLeft, faClipboard,
  faTv, faVideoCamera, faScissors, faCircleArrowLeft, faCircleArrowRight, faUpload,
  faCircleCheck, faCircleXmark, faCaretDown, faClockRotateLeft, faChartLine, faClock,
  faGear, faArrowRightArrowLeft, faUserSlash, faLockOpen, faLessThanEqual, faCaretUp
} from '@fortawesome/free-solid-svg-icons';
import { faSquare } from "@fortawesome/free-regular-svg-icons"

import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

library.add(
  faChevronDown, faChevronUp, faFutbol, faBriefcase, faPlus, faPencil,
  faMagnifyingGlass, faChevronLeft, faChevronRight, faX, faDoorOpen, faTrophy, faEquals,
  faEye, faEyeSlash, faPersonRunning, faTrashCan, faCheck, faRotate, faXmark, faMinus,
  faShieldHalved, faUser, faUsers, faFileArrowUp, faBinoculars, faQuestion, faArrowUp,
  faFacebook, faTwitter, faInstagram, faGlobe, faEllipsis, faCrown, faArrowDown, faPeopleGroup,
  faYoutube, faUserTie, faStar, faPersonWalkingDashedLineArrowRight, faImage, faVideo,
  faChartPie, faEarthAmericas, faList, faShareNodes, faTable, faGrip, faLock,
  faSquare, faSquareCheck, faSlash, faThumbTack, faArrowRight, faArrowLeft, faClipboard,
  faTv, faVideoCamera, faScissors, faCircleArrowLeft, faCircleArrowRight, faUpload,
  faCircleCheck, faCircleXmark, faCaretDown, faClockRotateLeft, faChartLine, faClock,
  faGear, faArrowRightArrowLeft, faUserSlash, faLockOpen, faLessThanEqual, faCaretUp);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);

import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import "./assets/scss/index.scss";

Vue.use(BootstrapVue)

import DefaultLayout from "./layout/Default.vue";
import FullScreenLayout from "./layout/FullScreen.vue";

Vue.component("default-layout", DefaultLayout);
Vue.component("full-screen-layout", FullScreenLayout);

// global components
import Icon from "@/components/Icon"
Vue.component('Icon', Icon);

import VerticalRange from "@/components/widgets/VerticalRange";
Vue.component("VerticalRange", VerticalRange);

import Loading from "@/components/Loading";
Vue.component("Loading", Loading);

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
Vue.component("v-select", vSelect);

import CountryFlag from "vue-country-flag"
Vue.component('CountryFlag', CountryFlag);

import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/es/es.js';
Vue.component("DatePicker", DatePicker);

// Notifications
import Notifications from "vue-notification"
Vue.use(Notifications);

// Charts
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
