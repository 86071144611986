import Vue from "vue";

function getSnapshot(state, id, scoutType) {
    let snapshot = state.snapshots.find(s => s.id === id && s.scoutType === scoutType);
    if (snapshot !== undefined) {
        return snapshot.snapshot;
    }
    return undefined;
}

export const snapshot = {
    namespaced: true,
    state: {
        snapshots: []
    },
    actions: {
        addSnapshot({ commit }, { id, scoutType, snapshot }) {
            commit('addSnapshot', { id, scoutType, snapshot });
        },
        reset({ commit }) {
            commit('reset')
        }
    },
    mutations: {
        addSnapshot(state, { id, scoutType, snapshot }) {
            let exists = getSnapshot(state, id, scoutType) !== undefined;
            if (exists) { return; }
            state.snapshots.push({ id, scoutType, snapshot });
            Vue.set(state, 'snapshots', state.snapshots);
        },
        reset(state) {
            Vue.set(state, 'snapshots', []);
        }
    },
    getters: {
        getSnapshot: state => (id, scoutType) => getSnapshot(state, id, scoutType)
    }
}
