export function buildPaginationQuery(endpoint, { page, take, search, sortBy, sortOrder }) {
    endpoint += `?page=${page}&take=${take}`
    if (search !== undefined && search !== "" && search !== null) {
        endpoint += `&search=${search}`;
    }
    if (sortBy !== undefined && sortBy !== "" && sortBy !== null) {
        endpoint += `&sortBy=${sortBy}`;
    }
    if (sortOrder !== undefined && sortOrder !== "" && sortOrder !== null) {
        endpoint += `&sortOrder=${sortOrder}`;
    }
    return endpoint;
}