import Repository from "./repository"

import { buildPaginationQuery } from "../pagination/index";

const resource = "team"

export default {
    async add(payload) {
        Repository.headers = {
            ...Repository.headers,
            'Content-Type': 'multipart/form-data'
        };
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async get({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async delete({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    },
    async setFavorite(payload) {
        let id = payload.id;
        let endpoint = `${resource}/${id}/favorite`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async getPreferences({ id }) {
        let endpoint = `${resource}/${id}/preferences`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async updatePreferences(payload) {
        let endpoint = `${resource}/${payload.teamId}/preferences`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async update({ id, payload }) {
        Repository.headers = {
            ...Repository.headers,
            'Content-Type': 'multipart/form-data'
        };
        let endpoint = `${resource}/${id}`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async changeLeague(payload) {
        let endpoint = `${resource}/${payload.teamId}/league`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async getAll(leagueId) {
        let endpoint = `${resource}/all`;
        if (leagueId !== undefined && leagueId !== null) {
            endpoint += `?leagueId=${leagueId}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPaged(params) {
        let endpoint = `${resource}/paged`;
        endpoint = buildPaginationQuery(endpoint, params);
        let category = params.category;
        if (category !== undefined && category !== "" && category !== null) {
            endpoint += `&category=${category}`;
        }
        let favorite = params.favorite;
        if (favorite !== undefined && favorite !== null) {
            endpoint += `&favorite=${favorite}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPagedByLeague({ id, page, take, search }) {
        let endpoint = `${resource}/${id}/league?page=${page}&take=${take}`;
        if (search !== undefined && search !== "" && search !== null) {
            endpoint += `&search=${search}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    }
}