import Vue from "vue";

export const leagues = {
    namespaced: true,
    state: {
        league: null
    },
    actions: {
        setLeague({ commit }, league) {
            commit('setLeague', league);
        },
        reset({ commit }) {
            commit('reset')
        }
    },
    mutations: {
        setLeague(state, league) {
            Vue.set(state, 'league', league);
        },
        reset(state) {
            Vue.set(state, 'league', null);
        }
    },
    getters: {
        getLeague: state => state.league
    }
}