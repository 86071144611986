<template>
    <b-navbar-nav class="fw-light ms-auto main-bar">
        <b-nav-item-dropdown text="Language" right>
            <template #button-content>
                <Icon name="fa-earth-americas" />
            </template>
            <b-dropdown-item
                v-for="lang in languages"
                :key="lang.id"
                @click="change(lang.id)"
            >
                <CountryFlag :country="lang.country" size="normal" />
                <span class="ms-1">
                    {{ $t(`languages.${lang.id}`) }}
                </span>
            </b-dropdown-item>
        </b-nav-item-dropdown>
    </b-navbar-nav>
</template>

<script>
const localeKey = "scoutyoupro:locale";

export default {
    name: "language-select",
    data() {
        return {
            languages: [
                { id: "es", country: "es" },
                { id: "en", country: "gb" },
            ],
        };
    },
    mounted() {
        let locale = this.$i18n.locale;

        let storedLocale = localStorage[localeKey];
        if (storedLocale !== undefined && storedLocale !== locale) {
            this.setLanguage(storedLocale);
        }
    },
    methods: {
        setLanguage(lang) {
            this.$i18n.locale = lang;
            localStorage[localeKey] = lang;
        },
        change(lang) {
            if (this.$i18n.locale !== lang) {
                this.setLanguage(lang);
                this.$router.go();
            }
        },
    },
};
</script>