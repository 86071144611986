import Repository from "./repository";

import { buildPaginationQuery } from "../pagination/index";

const resource = "player"

export default {
    async add(payload) {
        Repository.headers = {
            ...Repository.headers,
            'Content-Type': 'multipart/form-data'
        };
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async get({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async update({ id, payload }) {
        Repository.headers = {
            ...Repository.headers,
            'Content-Type': 'multipart/form-data'
        };
        let endpoint = `${resource}/${id}`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async setFavorite(payload) {
        let id = payload.id;
        let endpoint = `${resource}/${id}/favorite`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async signTeam(payload) {
        let id = payload.id;
        let endpoint = `${resource}/${id}/sign`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async getSeasons({ id }) {
        let endpoint = `${resource}/${id}/seasons`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPlayerAverageRatings({ id }) {
        let endpoint = `${resource}/${id}/ratings`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPaged(params) {
        let endpoint = `${resource}/paged`;
        endpoint = buildPaginationQuery(endpoint, params);
        let category = params.category;
        if (category !== undefined && category !== "" && category !== null) {
            endpoint += `&category=${category}`;
        }
        let favorite = params.favorite;
        if (favorite !== undefined && favorite !== null) {
            endpoint += `&favorite=${favorite}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPagedSearch({ filters, page, take, sortBy, sortOrder }) {
        let endpoint = `${resource}/search`;
        filters.page = page;
        filters.take = take;
        filters.sort = {
            by: sortBy,
            order: sortOrder
        };
        let response = await Repository.post(endpoint, filters);
        return response;
    },
    async getPagedByTeam({ id, page, take, search }) {
        let endpoint = `${resource}/team/${id}?page=${page}&take=${take}`;
        if (search !== undefined && search !== "" && search !== null) {
            endpoint += `&search=${search}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getAllByTeam(params) {
        let endpoint = `${resource}/team/${params.id}/all`;
        endpoint = buildPaginationQuery(endpoint, params);
        let average = params.average;
        if (average !== undefined && average !== null) {
            endpoint += `&average=${average}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async delete({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    },
    async getSharedPlayer({ id }) {
        let endpoint = `${resource}/${id}/share/complete`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPlayerShare({ id }) {
        let endpoint = `${resource}/${id}/share`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async share({ id }) {
        let endpoint = `${resource}/${id}/share`;
        let response = await Repository.put(endpoint);
        return response;
    },
    async unshare({ id }) {
        let endpoint = `${resource}/${id}/share`;
        let response = await Repository.delete(endpoint);
        return response;
    }
}