import Repository from "./repository"

const resource = "language";

export default {
    async get(lang) {
        let endpoint = `${resource}/${lang}`;
        let response = await Repository.get(endpoint);
        return response;
    }
}